import React, { createContext, useContext, useMemo, useState } from "react";
import PropTypes from 'prop-types';

const customizeContext = createContext();

export const useCustomizeContext = () => useContext(customizeContext);

export const CustomizeProvider = ({ children }) => {
    const [copyrightstext, setCopyrightstext] = useState("");
    const [twitter, setTwitter] = useState("");
    const [telegram, setTelegram] = useState("");
    const [youtube, setYoutube] = useState("");
    const [facebook, setFacebook] = useState("");
    const [footerContent, setFooterContent] = useState("");
    const [whitePaperLink, setWhitePaperLink] = useState("");
    const [userId, setUserId] = useState('');
    const [userAuthToken, setUserAuthToken] = useState("");

    const [discord, setDiscord] = useState("");
    const [linkedin, setLinkedin] = useState("");
    const [instagram, setInstagram] = useState("");



    

    const siteInformation = useMemo(() => ({
        telegram, setTelegram,
        twitter, setTwitter,
        youtube, setYoutube,
        copyrightstext, setCopyrightstext,
        whitePaperLink, setWhitePaperLink,
        facebook, setFacebook,
        footerContent, setFooterContent,
        discord, setDiscord,
        linkedin, setLinkedin,
        instagram, setInstagram
    }), [telegram, twitter, youtube, copyrightstext, whitePaperLink, facebook, footerContent, discord,instagram,linkedin]);

    const userInfo = useMemo(() => ({
        userId, setUserId,
        userAuthToken, setUserAuthToken,
    }), [userId, userAuthToken]);

    const contextValue = useMemo(() => ({
        siteInformation,
        userInfo,
    }), [siteInformation, userInfo]);

    return (
        <customizeContext.Provider value={contextValue}>
            {children}
        </customizeContext.Provider>
    );
};

// Add PropTypes validation
CustomizeProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default CustomizeProvider;
