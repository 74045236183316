import arrup from '../../assets/images/arrow-up.png'
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer12/Footer";
import { useEffect, useState } from 'react'
import { CONST } from '../../config'
import Helper from '../../common/Helper'
import ScaleLoader from "react-spinners/ScaleLoader";


const AboutUs = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        getData()
    }, []);

    const [cmsdata, setCmsdata] = useState()
    const [loading, setLoading] = useState(false);
    let color = "#ffffff";



    const getData = async () => {
        setLoading(true);
        let getdata = await Helper.GetData(`${CONST?.BACKEND_URL}/Yq7ss7Xwd8Jc/EKu1mCix4vAxDWLki7Vq`);
        if (getdata?.status) {
            setCmsdata(getdata.aboutus[0])
        }
        setLoading(false);
    }
    return (
        <>
            <div className={loading ? "spinner" : "d-none"}>
                <ScaleLoader color={color} size={50} />
            </div>
            <Header />
            <div className="CntLdDv">
                <div className='container'>
                    <div className="AbtUsPg">
                        <p>About Us</p>
                        <h1>Setting Up Standard On<br />
                            <span>Culture Of Working</span></h1>
                        <div className='AbtBg' style={{ backgroundImage: `url(${cmsdata?.backimage})` }}>
                            <div className=' AbtBgcnt'>
                                <h5>{cmsdata?.title1}</h5>
                                <p>{cmsdata?.content}</p>
                                <a href='#l'>Learn More<img src={arrup} className='ms-2' alt="" /></a>
                            </div>
                        </div>
                        <div className='row AbtCasDv'>
                            <div className='col-lg-6'>
                                <h1>We Do This<br /><span>For A Single Cause</span></h1>
                            </div>
                            <div className='col-lg-6'>
                                <p>{cmsdata?.section2content}</p>
                                <div className='AbtCasDvPrfl'>
                                    <img src={cmsdata?.profileimage} className='me-3' alt="" />
                                    <div className='rdv'>
                                        <h5>{cmsdata?.name}</h5>
                                        <h6>{cmsdata?.desigination}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='AbtDevlDv'>
                            <h2>{cmsdata?.teamtitle}</h2>
                            <h3>{cmsdata?.teamcontent}</h3>
                            <div className='AbtDevDvTm'>
                                <div className='row'>
                                    {cmsdata?.team?.map((item, index) => (
                                        <div className='col-lg-3 col-md-6' key={item.id || index}>
                                            <div className='AbtDevDvTmCnt'>
                                                <img src={item?.image} alt="" />
                                                <div className='AbtDevDvTmCntDts'>
                                                    <h5>{item?.name}</h5>
                                                    <h6>{item?.designation}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );

}

export default AboutUs;