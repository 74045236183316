import React from "react";
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import { EffectCoverflow, Navigation } from 'swiper/modules';

const Roadmap = ({ homepagedata }) => {

  return (
    <div className="roadmapkey LghtBg cmntxt" id="roadmap">
      <div className="container">
        <div className="CmmnHdd text-center">
          <h6>{homepagedata?.tittlesix}</h6>  
          {/* //Key Milestones */}
        </div>
        <div className="mnhgt mt-5">
          <div className="RdMpSldr">

            <Swiper
              effect={'coverflow'}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={3}
              coverflowEffect={{
                rotate: 60,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
              }}
              navigation={true}
              modules={[EffectCoverflow, Navigation]}
              loop={true}
              className="mySwiper"
              breakpoints={{
               
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },

                768: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                991: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
            }}

            >

{homepagedata?.roadmap && (    
  homepagedata?.roadmap.map((item, index) => (  
              <SwiperSlide>
                <div className="posrel1" >
                  <div className="roadbg roadbg1">
                    <div className="" style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.2)' }}>
                      {/* <h6>{item.title.split(" ")[0]}</h6> */}
                      <h6>{item?.title}</h6>
                    </div>
                  
                    <div className="RdMpScrl"
                        dangerouslySetInnerHTML={{ __html: item.content }}
                      />
                  </div>
                </div>
              </SwiperSlide>
  ))

)}

            </Swiper>
          </div>

        </div>

      </div>
    </div>
  );
};
Roadmap.propTypes = {
  homepagedata: PropTypes.object.isRequired,
};

export default Roadmap;
