import React from "react";
import PropTypes from 'prop-types';
import security from '../../../assets/images/security.png'
import invest from '../../../assets/images/invest-img.webp'
import icon1 from '../../../assets/images/invest-1.svg'
import icon2 from '../../../assets/images/invest-2.svg'
import icon3 from '../../../assets/images/invest-3.svg'
import icon4 from '../../../assets/images/invest-4.svg'
import icon5 from '../../../assets/images/invest-5.svg'
import icon6 from '../../../assets/images/invest-6.svg'



const Invest = ({ homepagedata }) => {


    return (
        <div className="investSec">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <div className="investTp">
                            <h2>Why Invest</h2>
                        </div>
                    </div>
                    <div className="col-lg-1"></div>
                    <div className="col-lg-5">
                        <div>
                            <img src={invest} alt="Why Invest" title="Why Invest" loading="lazy" />
                        </div>
                    </div>
                </div>


                <div className="row investDts">
                    <div className="col-lg-4">
                        <div className="investDtsCnt">
                            <div className="investDtsCntTp">
                                <img src={homepagedata?.image1}  alt="Value Appreciation" title="Value Appreciation" loading="lazy" />
                                <h4>{homepagedata?.cardonetitle}</h4>
                            </div>
                            <p>{homepagedata?.cardonedescription}</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="investDtsCnt">
                            <div className="investDtsCntTp">
                                <img src={homepagedata?.image2}  alt="Passive Income" title="Passive Income" loading="lazy" />
                                <h4>{homepagedata?.cardtwotitle}</h4>
                            </div>
                            <p>{homepagedata?.cardtwodescription}</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="investDtsCnt">
                            <div className="investDtsCntTp">
                                <img src={homepagedata?.image3}  alt="Governance Participation" title="Governance Participation" loading="lazy" />
                                <h4>{homepagedata?.cardthreetitle}</h4>
                            </div>
                            <p>{homepagedata?.cardthreedescription}</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="investDtsCnt">
                            <div className="investDtsCntTp">
                                <img src={homepagedata?.image4}  alt="Diverse Utility" title="Diverse Utility" loading="lazy" />
                                <h4>{homepagedata?.cardfourtitle}</h4>
                            </div>
                            <p>{homepagedata?.cardfourdescription}</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="investDtsCnt">
                            <div className="investDtsCntTp">
                                <img src={homepagedata?.image5}  alt="Early Entry Advantage" title="Early Entry Advantage" loading="lazy" />
                                <h4>{homepagedata?.cardfivetitle}</h4>
                            </div>
                            <p>{homepagedata?.cardfivedescription}</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="investDtsCnt">
                            <div className="investDtsCntTp">
                                <img src={homepagedata?.image6}  alt="Ecosystem Growth Rewards" title="Ecosystem Growth Rewards" loading="lazy" />
                                <h4>{homepagedata?.cardsixtitle}</h4>
                            </div>
                            <p>{homepagedata?.cardsixdescription}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
Invest.propTypes = {
    homepagedata: PropTypes.object.isRequired,
};

export default Invest;