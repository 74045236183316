import React from "react";
import PropTypes from 'prop-types';



const Features = ({ homepagedata }) => {

    return (
        <div className="featSec">
            <div className="container">
                <div className="featTp">
                    <h2>Standout Features</h2>
                </div>
            </div>
            <div className="featDts">
                <div className="container">
                    <div className="row">
                    {homepagedata?.team?.map((faqItem, index) => (
                        <div className="col-xl-4 col-lg-6">
                            <div className="featDtsCnt">
                                <div className="featDtsCntTp">
                                    <img src={faqItem?.image} alt="Ethereum-Based Security" title="Ethereum-Based Security" loading="lazy" />
                                    <h4>{faqItem?.name}</h4>
                                </div>
                                <p>{faqItem?.designation}</p>
                                  
                            </div>
                        </div>
                    ))}
                        
                    </div>
                </div>
            </div>
        </div>
    );

}
Features.propTypes = {
    homepagedata: PropTypes.object.isRequired,
};

export default Features;