import React from "react";
import PropTypes from 'prop-types';
import future1 from '../../../assets/images/future-1.png'
import future2 from '../../../assets/images/future-2.png'
import rocket from '../../../assets/images/rocket.png'
import could from '../../../assets/images/could.png'

const Future = ({ homepagedata }) => {
console.log("🚀 ~ Future ~ homepagedata:", homepagedata)


    

    return (
        <div className="futureSec">
            <div className="container">
                <div className="futureTp">
                    <h2>Future Scope</h2>
                </div>





                <div className="row">
                    {/* {homepagedata && homepagedata ? */}
                    <div className="col-lg-5 text-lg-end">
                        <div>
                            <img src={future1} className="d-lg-block d-none" />
                        </div>

                        {homepagedata && homepagedata?.futurescope?.map((futurescopes, index) => {
                            if (index % 2 === 0) {
                                return(       
                                    <div className="futureDtsCnt">
                                        <div className="futureDtsCntTp">
                                        <p className="count ">0{index+1}</p>
                                            <h4>{futurescopes?.title}</h4>
                                        </div>
                                        <p>{futurescopes?.description}</p>                         
                                    </div>
                                ) 
                            }
                        })}

                    </div>
                   {/* :"" } */}
                    <div className="col-lg-2">
                        <div className="futureDiv">
                            <img src={rocket} className="d-lg-block d-none" />
                        </div>
                    </div>
                    {/* {homepagedata && homepagedata ? */}
                    <div className="col-lg-5">
                        <div>
                            <img src={future2} className="d-lg-block d-none" />
                        </div>
                        {homepagedata && homepagedata?.futurescope?.map((futurescopes, index) => {
                            if (index % 2 !== 0) {
                        return(      
                        <div className="futureDtsCnt">
                            <div className="futureDtsCntTp">
                                <p className="count ">0{index+1}</p>
                                <h4>{futurescopes?.title}</h4>
                            </div>
                            <p>{futurescopes?.description}</p>     
                        </div>
                        )
                            }
                        })}                      
                    </div>
                    {/* :""} */}

                </div>
                <div className="text-center d-flex justify-content-center">
                    <img src={could} className="d-lg-flex d-none" />
                </div>
            </div>
        </div>
    );

}
Future.propTypes = {
    homepagedata: PropTypes.any,
};

export default Future;