import "./scss/footer.scss";
import React, { Fragment, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { } from 'reactstrap';

import facebookLogo from '../../assets/images/facebook.png';
import twitterLogo from '../../assets/images/twitter.png';
import linkedinLogo from '../../assets/images/linkedin.png';
import discordLogo from '../../assets/images/brand-discord.png';


import { useCustomizeContext } from "../Context";

const Footer = () => {

	const pageLocation = useLocation();
	const [pageType, setPageType] = useState("home");

	const {

		siteInformation: {
			twitter,
			copyrightstext,
			facebook,
			discord,
		},
	} = useCustomizeContext();



	useEffect(() => {
		if (pageLocation.pathname === "/") {
			setPageType("home")
		}
		else {
			setPageType("inner")
		}
	}, [pageLocation])


	React.useEffect(() => { window.scrollTo(0, 0); }, []);
	return (
		<Fragment>

			{pageType === "home" ?

				<footer className="indFt">
					<div className="container">
						<div className="ftSclCon">
							<ul className="ftSclCon">
								<li>
									<a href={twitter} target="_blank" id="telegramLink" >
										<img src={twitterLogo} alt="fb.png" />
									</a>
								</li>
								<li>
									<a href={facebook} target="_blank" id="discordLink">
										<img src={facebookLogo} alt="insta.png" />
									</a>
								</li>
								<li>
									<a href={discord} target="_blank" id="discordLink">
										<img src={linkedinLogo} alt="insta.png" />
									</a>
								</li>
							</ul>

						</div>

						<div className="ftCp">
							© {copyrightstext}
						</div>
					</div>
				</footer>

				:
				<footer className="indFt inr">
					<div className="container">
						<div className="ftSclCon">
							<ul className="ftSclCon">
								<li>
									<a href={twitter} target="_blank" id="telegramLink" >
										<img src={twitterLogo} alt="fb.png" />
									</a>
								</li>
								<li>
									<a href={facebook} target="_blank" id="discordLink">
										<img src={facebookLogo} alt="insta.png" />
									</a>
								</li>
								<li>
									<a href={discord} target="_blank" id="discordLink">
										<img src={discordLogo} alt="insta.png" />
									</a>
								</li>
							</ul>
						</div>

						<div className="ftCp">
							© {copyrightstext}
						</div>
					</div>
				</footer>
			}
		</Fragment>
	);

}

export default Footer;