import React from "react";
import PropTypes from 'prop-types';
import ovr from '../../../assets/images/ovr-img.webp'
import bg from '../../../assets/images/bg.webp';


const Overview = ({ homepagedata }) => {


    return (
        <div className="OvrVwSec">
            <div className="posImg">
                <img src={bg} />
            </div>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div>
                            <img src={ovr} className="img-fluid" alt="Blockwave - About" title="Blockwave - About" loading="lazy" />
                        </div>
                    </div>
                    <div className="col-lg-6 order-lg-0 order-first">
                        <div className="ovrVwTp">
                            {/* <h2>Blockwave - About</h2> */}
                            <h2>{homepagedata?.tittletwo}</h2>
                            <p>
                                {homepagedata?.tittletwosubtitle}
                                </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );

}
Overview.propTypes = {
    homepagedata: PropTypes.object.isRequired,
};

export default Overview;