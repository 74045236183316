import "./scss/register.scss";
import Header from "../../common/Header/Header"
import Footer from "../../common/Footer/Footer"
import React, { Fragment, useEffect, useRef, useState } from "react";
import register1 from "../../assets/images/register.svg";
import { useForm } from "react-hook-form";
import { CONST } from '../../config'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from "react-spinners/ClipLoader";
import Helper from "../../common/Helper";
import ReCAPTCHA from "react-google-recaptcha";

const Register = () => {
  const recaptchaRef = useRef(null);
  toast.configure()
  const { register, handleSubmit, getValues, formState: { errors } } = useForm({ mode: "onChange" });
  const navigate = useNavigate()

  const [showeye, setShoweye] = useState(false);
  const [showeyecon, setShoweyecon] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);

  useEffect(() => {
    if (localStorage.getItem('loginId')) {
      navigate("/");
    }
  }, []);

  const onCaptchaChange = (value) => {
    setCaptchaValue(value);
  };
  const onSubmit = async (data) => {

    if (!captchaValue) {
      // If reCAPTCHA value is not available, prevent form submission
      toast.error('Please complete the reCAPTCHA verification');
      return;
    }
    setButton(true)
    const data1 = {
      "firstname": data.fname,
      'lastname': data.lname,
      'email': data.email,
      'username': data.username,
      'password': data.password,
      'confirm_password': data.cpassword,
      'google_captcha': captchaValue
    }
    let getdata = await Helper.PostData(`${CONST?.BACKEND_URL}/Yq7ss7Xwd8Jc/Zi90QiQLBJlK5GTitPxp`, data1);
    if (getdata?.status) {
      setButton(false)
      toast.success(getdata?.msg)
      navigate('/home');
    }
    else {
      setButton(false)
      toast.error(getdata?.msg);
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
      setCaptchaValue(null);
    }
  }
  const [button, setButton] = useState(false)

  const AvoidSpace = (event) => {
    const k = event.which || event.keyCode;
    if (k === 32) {
      event.preventDefault();
    }
  };

  return (
    <Fragment>
      <Header />
      <div className="cntld">
        <div className="w-100 LoginRegContainer">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-5 d-flex justify-content-center align-items-center">
                    <img alt="register" src={register1} className="img-fluid" />
                  </div>
                  <div className="col-lg-7">
                    <form className="loginRegForm" onSubmit={handleSubmit(onSubmit)}>
                      <div className="heading">
                        <h5>Few Steps Away !!!</h5>
                        <h3>Register Now</h3>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 mb-3">
                          <label className="form-label" htmlFor="firstName">First Name<span className="mandatory">*</span></label>
                          <input type="text" name="fname"
                            className="form-control"
                            placeholder="First Name"
                            id='firstname'
                            {...register('fname', {
                              required: true,
                              pattern: /^[a-zA-Z]+( [a-zA-Z]+)*$/
                            })}
                            minLength="1"
                            maxLength="20"
                          />
                          {errors.fname && errors.fname.type === 'required' && <span className='errors'>Please Enter the First Name</span>}
                          {errors.fname && errors.fname.type === 'pattern' && <span className='errors'>Please Enter Valid First Name</span>}

                        </div>
                        <div className="col-lg-6 mb-3">
                          <label className="form-label" htmlFor="lastName">Last Name<span className="mandatory">*</span></label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            {...register('lname', {
                              required: true,
                              pattern: /^[a-zA-Z]+( [a-zA-Z]+)*$/
                            })}
                            minLength="1"
                            maxLength="20"
                          />
                          {errors.lname && errors.lname.type === 'required' && <span className='errors'>Please Enter the Last Name</span>}
                          {errors.lname && errors.lname.type === 'pattern' && <span className='errors'>Please Enter Valid Last Name</span>}

                        </div>
                        <div className="col-lg-6 mb-3">
                          <label className="form-label" htmlFor="userName">Username<span className="mandatory">*</span></label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Username"
                            {...register('username', { required: true, pattern: /^[a-zA-Z][\sa-zA-Z]*/ })}
                            minLength="1"
                            maxLength="15"
                          />
                          {errors.username && errors.username.type === 'required' && <span className='errors'>Please Enter the Username</span>}
                          {errors.username && errors.username.type === 'pattern' && <span className='errors'>Please valid the Username</span>}

                        </div>
                        <div className="col-lg-6 mb-3">
                          <label className="form-label" htmlFor="email">Email<span className="mandatory">*</span></label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            onKeyDown={(event) => AvoidSpace(event)}
                            {
                            ...register('email', {
                              required: true,
                              pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            })
                            }
                          />
                          {errors.email && errors.email.type === 'required' && <span className='errors'>Please Enter the Email</span>}
                          {errors.email && errors.email.type === 'pattern' && <span className='errors'>Please Enter valid Email</span>}
                        </div>
                        <div className="col-lg-6 mb-3">
                          <label className="form-label" htmlFor="password">Password<span className="mandatory">*</span></label>
                          <div className="eyeIcon">
                            <input
                              type={showeye ? 'text' : 'password'}
                              name='password'
                              className="form-control"
                              placeholder="Password"
                              onKeyDown={(event) => AvoidSpace(event)}
                              {...register('password', { required: { value: true, message: "Password Is Required" }, pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/ })}
                            />
                            <button className="btn" type="button" onClick={() => setShoweye(!showeye)}>
                              {showeye ?
                                <img src={require('../../assets/images/eye.svg').default} className='img-fluid eye-show' alt="eye" />
                                :
                                <img src={require('../../assets/images/eye-slash.svg').default} className='img-fluid eye-hide' alt="eye-slash" />
                              }
                            </button>
                          </div>
                          {errors.password && errors.password.type === 'required' && (
                            <span className="errors">{errors.password.message}</span>
                          )}
                          {errors.password && errors.password.type === 'pattern' && <span className='errors'>Password Must Contain Uppercase, Special Characters, Numbers</span>}
                        </div>
                        <div className="col-lg-6 mb-3">
                          <label className="form-label" htmlFor="confirmPassword">Confirm Password<span className="mandatory">*</span></label>
                          <div className="eyeIcon">
                            <input
                              type={showeyecon ? 'text' : 'password'}
                              className="form-control"
                              placeholder="Confirm Password"
                              onKeyDown={(event) => AvoidSpace(event)}
                              {...register('cpassword', { required: true, validate: (value) => value === getValues("password") })}
                            />
                            <button className="btn" type="button" onClick={() => setShoweyecon(!showeyecon)}>
                              {showeyecon ?
                                <img src={require('../../assets/images/eye.svg').default} className='img-fluid eye-show' alt="eye" />
                                :
                                <img src={require('../../assets/images/eye-slash.svg').default} className='img-fluid eye-hide' alt="eye-slash" />
                              }
                            </button>
                          </div>
                          {errors.cpassword && errors.cpassword.type === 'required' && <span className='errors'>Please Enter the Confirm Password</span>}
                          {errors.cpassword && errors.cpassword.type === 'validate' && <span className='errors'>Passwords Must be Same</span>}
                        </div>
                        <div className="mb-3">
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={Helper.Sitekey}
                            onChange={onCaptchaChange}
                          />
                        </div>
                        <div className="col-lg-6 mb-3">
                          <button type="submit" className="btn btn-block orangeBtn w-100" disabled={button}>
                            {button ? <ClipLoader color={'#ffffff'} size={25} /> : <span>Register</span>}</button>
                        </div>
                        <div className="mb-3">
                          <p className="text-left">Have an account? <Link to='/login'>Login Now</Link></p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );

}

export default Register;