import "../Buytoken/scss/buytoken.scss";
import Header from "../../common/Header/Header"
import Footer from "../../common/Footer/Footer"
import React, { Fragment, useEffect, useState } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import depositone from "../../assets/images/deposit.png";
import withdrawone from "../../assets/images/withdraw.png";
import { Link, useNavigate } from 'react-router-dom';
import { CONST } from '../../config/index';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Helper from "../../common/Helper";
import PropTypes from "prop-types";


const Wallet = (props) => {
  toast.configure()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  let color = "#ffffff";

  useEffect(() => {
    fetchData()
    fetchData1()
  }, [])

  let count = 0;
  const [pairdetails, setPairdetails] = useState()
  const [walletdetails, setWalletdetails] = useState()

  const fetchData = async () => {
    let getdata = await Helper.PostData(`${CONST?.BACKEND_URL}/Yq7ss7Xwd8Jc/TSCxkKHYvCzGElrC2wUh`);
    if (getdata?.status) {
      setPairdetails(getdata?.data)
    }
  }

  const fetchData1 = async () => {
    setLoading(true);
    let getdata = await Helper?.PostData(`${CONST?.BACKEND_URL}/Axri1CZK2hX7/gl79YP2VipvfMO72obJM`);
    if (getdata?.status === true) {
      let cnBalance = getdata?.msg;
      await Helper?.GetData(`${CONST?.BACKEND_URL}/sDZuOFOBRwYg/oHDmRVfD8vqlOZzXq8fN`).then((res) => {
        if (res?.status === true) {
          let getallactivecurrency = res?.data;
          for (const balance of cnBalance) {
            for (const currency of getallactivecurrency) {
              if (currency.name === balance.currency) {
                balance.image = currency.image;
                balance.deposit_status = currency.deposit_status;
                balance.withdraw_status = currency.withdraw_status;
              }
            }
          }
          setWalletdetails(cnBalance);
        }
      });
    }
    setLoading(false);
  };


  const deposit = async (data) => {
    await props.setIsCurrency(data.currency)
    sessionStorage.setItem('curency', data.currency)
    setTimeout(() => {
      navigate('/deposit')
    }, 1000)
  }

  const withdraw = async (data) => {
    await props.setIsCurrency(data.currency)
    setTimeout(() => {
      navigate('/withdraw')
    }, 1000)
  }

  const renderButton = (data) => {
    if (data.currency === "BLOCKWAVE") {
      return (
        <button className="btn bluebtn me-2" onClick={() => navigate('/history')}>
          History
        </button>
      );
    } else if (data.deposit_status === "deactive") {
      return (
        <button className="btn bluebtn me-2" disabled>
          Deposit
        </button>
      );
    } else {
      return (
        <button className="btn bluebtn me-2" onClick={() => deposit(data)}>
          Deposit
        </button>
      );
    }
  };

  return (
    <Fragment>
      <div className={loading ? "spinner" : "d-none"}>
        <ScaleLoader color={color} size={50} />
      </div>
      <Header />
      <div className="cntld">
        <div className="w-100 walletContainer">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mb-3">
                <div className="walletBannerCnt">
                  <div className="bannerContent">
                    <h3>Manage & Buy BWT Token</h3>
                    <p className="w-100" style={{ fontSize: '13px' }}>Notes: Please make sure to maintain a balance of at least 0.05 ETH in your wallet for deposit purposes.</p>
                    <div className="d-flex mt-3">
                      <Link to='/deposit' className="btn orangeBtn me-2"><img src={depositone} alt="" className="me-2" />Deposit</Link>
                      <Link to='/withdraw' disabled className="btn orangeBtn"><img src={withdrawone} alt="" className="me-2" />Withdraw</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {pairdetails && pairdetails.length > 0 ? (
                pairdetails.map((pairData, index) => {
                  const walletData = walletdetails?.find(wallet => wallet.currency === pairData.name);
                  return (
                    <div className="col-lg-6" key={pairData.id || index}>
                      <div className="walletCoinCnt">
                        <div className="d-flex align-items-center">
                          <img src={pairData.image} alt="" className="me-2" style={{ borderRadius: "50px" }} />
                          <div className="d-flex flex-column text-left">
                            <h3>{pairData.pair}</h3>
                            <p>
                              {walletData ? walletData.amount.toFixed(8) : '0.00000000'} {pairData.name}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : <>
              </>
              }
            </div>
          </div>
        </div>
        <div className="walletHistoryCnt">
          <div className="histpg">
            <div className="container">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-12">

                  <div className="histtab">
                    <h3 className="p-4">My Wallet</h3>
                    <div className="table-responsive mb-0">
                      <table className="table">
                        <thead>
                          <th>S.No</th>
                          <th>Currency</th>
                          <th>Balance</th>
                          <th className="text-center">Action</th>
                        </thead>
                        <tbody>
                          {walletdetails?.length > 0 ? (
                            walletdetails?.map((data, index) => {
                              count += 1; // Extracted assignment

                              return (
                                <tr key={data.id || index}>
                                  <td>{count}</td>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <img src={data.image} className="me-2" alt="" style={{ width: "30px", borderRadius: "50%" }} />
                                      <div className="d-flex flex-column">
                                        <h3>{data.currency}</h3>
                                      </div>
                                    </div>
                                  </td>
                                  <td>{data.amount?.toFixed(8)} {data.currency}</td>
                                  <td>
                                    <div className="d-flex justify-content-center">
                                      {renderButton(data)}
                                      {data.withdraw_status === "deactive" ? (
                                        <button className="btn bluebtn" disabled>Withdraw</button>
                                      ) : (
                                        <button className="btn bluebtn" onClick={() => withdraw(data)}>Withdraw</button>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="4">
                                <span className="nodata">No Data Found</span>
                              </td>
                            </tr>
                          )}
                        </tbody>

                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );

}

Wallet.propTypes = {
  setIsCurrency: PropTypes.any.isRequired,
};

export default Wallet;