import "../Buytoken/scss/buytoken.scss";
import Header from "../../common/Header/Header"
import Footer from "../../common/Footer/Footer"
import React, { Fragment, useEffect, useState } from "react";

import leftarrowImg from "../../assets/images/leftarrow.png";

import { CONST } from "../../config";

import { useNavigate } from "react-router-dom";

import { useForm } from 'react-hook-form'
import { toast } from "react-toastify";
import ScaleLoader from "react-spinners/ScaleLoader";
import ClipLoader from "react-spinners/ClipLoader";
import Helper from "../../common/Helper";
import { NumericFormat } from 'react-number-format';


const Security = () => {

  const [tfaUrl, setTfaUrl] = useState('');
  const [tfaCode, setTfaCode] = useState('');
  const [tfaStatus, setTfaStatus] = useState(null);
  const [tfaOtp, setTfaOtp] = useState('')
  const [loading, setLoading] = useState(false);
  let color = "#ffffff";
  const [showeye, setShoweye] = useState(false);
  const [showeyenew, setShoweyenew] = useState(false);
  const [showeyecon, setShoweyecon] = useState(false);

  const Navigate = useNavigate()
  const [button, setButton] = useState(false)

  const { register, handleSubmit, getValues, formState: { errors } } = useForm({ mode: "onChange" });


  useEffect(() => {
    fetchData()
    tfadata()
  }, [])

  const tfadata = async () => {
    setLoading(true);
    let getData = await Helper?.PostData(`${CONST?.BACKEND_URL}/Yq7ss7Xwd8Jc/t6gep9ZEy2XFauVM8o9P`);
    if (getData?.status) {
      setTfaStatus(getData?.user?.tfa_status)
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  const fetchData = async () => {
    let data = {
      "userId": localStorage.getItem('loginId')
    }
    setLoading(true);
    let getData = await Helper?.PostData(`${CONST?.BACKEND_URL}/Yq7ss7Xwd8Jc/gTrlvLZ6ywA2PXaYGlR3`, data);
    if (getData?.status) {
      setTfaUrl(getData?.data?.tfa_url);
      setTfaCode(getData?.data?.tfa_code)
      setLoading(false);
    }

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  const tfaVerify = (data) => {
    setTfaOtp(data.target.value)
  }

  const tfaEnable = async (e) => {
    e.preventDefault()

    if (tfaOtp) {
      let data = {
        'secret': tfaCode ?? tfaCode,
        'tfa_code': tfaOtp ?? tfaOtp
      }
      setLoading(true);
      let getdata = await Helper?.PostData(`${CONST?.BACKEND_URL}/Yq7ss7Xwd8Jc/Y5bcqfFqKN6rSlByI9Ii`, data);
      if (getdata?.status) {
        fetchData()
        toast.success('TFA Enabled Successfully')
        document.getElementById('tfacode').value = '';
        tfadata();
        fetchData();
        setTfaOtp("");
      }
      else if (!getdata?.status) {
        toast.error(getdata?.msg)
      }
      setLoading(false);
      setTfaOtp("");
    }
    else {
      toast.error("Tfa code is required !")
    }

  }

  const tfaDisable = async (e) => {
    e.preventDefault();
    if (tfaOtp) {
      let data = {
        'tfa_code': tfaOtp ?? tfaOtp
      }
      setLoading(true);
      let getdata = await Helper?.PostData(`${CONST?.BACKEND_URL}/Yq7ss7Xwd8Jc/Y5bcqfFqKN6rSlByI9Ii`, data);
      if (getdata?.status) {
        toast.success('TFA Disabled Successfully')
        document.getElementById('tfacode').value = '';
        fetchData();
        tfadata();
        setTfaOtp("");
      }
      else if (!getdata?.status) {
        toast.error(getdata.msg)
      }
      setLoading(false);
      setTfaOtp("");
    }
    else {
      toast.error("Tfa code is required !")

    }

  }


  const onSubmit = async (data) => {
    setButton(true)

    let data1 = {
      'password': data.newpassword,
      'new_pwd': data.newpassword,
      'confirm_password': data.cpassword,
      'current_pwd': data.currentpwd
    }

    let getPwd = await Helper.PostData(`${CONST?.BACKEND_URL}/Yq7ss7Xwd8Jc/wJtrO1L78bjvtAIt4pPv`, data1)

    if (getPwd?.status) {
      setButton(false)
      toast.success(getPwd?.msg)
      document.getElementById('currentpwd').value = '';
      document.getElementById('newpassword').value = '';
      document.getElementById('cpassword').value = '';
      sessionStorage.removeItem('loginId')

      setTimeout(() => {
        Navigate('/login');
        window.location.reload()

      }, 2000)
    }
    else if (!getPwd?.status) {
      setButton(false)

      toast.error(getPwd?.msg)
    }

  }
  const Back = () => {
    Navigate(-1);


  }

  const AvoidSpace = (event) => {
    const k = event.which || event.keyCode;
    if (k === 32) {
      event.preventDefault();
    }
  };

  return (
    <Fragment>
      <div className={loading ? "spinner" : "d-none"}>
        <ScaleLoader color={color} size={50} />
      </div>
      <Header />
      <div className="cntld">
        <div className="w-100 walletContainer">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="DepositWithdrawCnt myProfile">
                  <button
                    className="head d-flex align-items-center pointer"
                    style={{
                      all: 'unset', // Reset all styles
                      cursor: 'pointer', // Change cursor to pointer
                      background: 'transparent', // Transparent background
                    }}
                    onClick={Back}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === 'Space') {
                        Back();
                      }
                    }}
                  >
                    <img src={leftarrowImg} className="me-2" alt="" />
                    <h3 className="mb-0">Security</h3>
                  </button>
                  <div className="row justify-content-center">
                    <div className="col-lg-10">
                      <form >
                        <div className="col-12 tfaAuthRow">
                          <h4>2 Factor Authentication (Google Authenticator) - {tfaStatus && tfaStatus == 1 ? <span className="text-success">Enabled</span> : <span className="text-danger">Disabled</span>}
                          </h4>
                        </div>
                        <div className="row">
                          {tfaStatus && tfaStatus == 1 ?
                            null
                            :
                            <div className="col-lg-6 my-3">
                              <label className="form-label" htmlFor="qrCode">Scan QR Code</label>
                              <div className="qrCodeCnt">
                                <img src={tfaUrl ?? tfaUrl} className="img-fluid" alt="" />
                              </div>
                            </div>
                          }
                        </div>
                        <div className="row">
                          <div className="col-12 tfaAuthRow">
                            {tfaStatus && tfaStatus == 1 ?
                              <></>
                              :
                              <h3 className="authCode">{tfaCode ?? tfaCode}</h3>
                            }
                            <p>Enter 6 digit Google Authentication code(this entered code will be valid for 1 minute 30 seconds)</p>
                          </div>
                          <div className="col-lg-6 mb-3">
                            <label className="form-label" htmlFor="twoFA">Enter 2FA Code<span className="mandatory">*</span></label>
                            <NumericFormat
                              id='tfacode'
                              className="form-control user"
                              placeholder="2FA Code"
                              onChange={tfaVerify}
                              allowLeadingZeros
                              value={tfaOtp}
                              maxLength="6"
                              required
                            />
                          </div>
                        </div>
                        <div className="row">
                          {tfaStatus == 1 ?
                            <div className="col-lg-6 my-3">
                              <button className="btn orangeBtn" onClick={tfaDisable}>Disable</button>
                            </div> :
                            <div className="col-lg-6 my-3">
                              <button className="btn orangeBtn" onClick={tfaEnable}>Enable</button>
                            </div>}
                        </div>
                      </form >
                      <hr className="my-5" />
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                          <div className="col-12 tfaAuthRow">
                            <h4>Change Password</h4>
                          </div>
                          <div className="col-lg-6 mb-3">
                            <label className="form-label" htmlFor="currentPassword">Enter Current Password<span className="mandatory">*</span></label>
                            <div className="eyeIcon">
                              <input
                                type={showeye ? 'text' : 'password'}
                                id='currentpwd'
                                className="form-control user"
                                placeholder="Current Password"
                                onKeyDown={(event) => AvoidSpace(event)}
                                {...register('currentpwd', { required: true })}
                              />
                              <button className="btn" type="button" onClick={() => setShoweye(!showeye)}>
                                {showeye ?
                                  <img src={require('../../assets/images/eye.svg').default} className='img-fluid eye-show' alt="eye" />
                                  :
                                  <img src={require('../../assets/images/eye-slash.svg').default} className='img-fluid eye-hide' alt="eye-slash" />
                                }
                              </button>
                            </div>
                            {errors.currentpwd && errors.currentpwd.type === 'required' && <span className="errors">Please Enter Current Password</span>}
                          </div>
                          <div className="col-lg-6 mb-3">
                            <label className="form-label" htmlFor="newPassword">Enter New Password<span className="mandatory">*</span></label>
                            <div className="eyeIcon">
                              <input
                                type={showeyenew ? 'text' : 'password'}
                                id='newpassword'
                                className="form-control user"
                                placeholder="New Password"
                                onKeyDown={(event) => AvoidSpace(event)}
                                {...register('newpassword', { required: { value: true, message: "Password Is Required" }, pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/ })}
                              />
                              <button className="btn" type="button" onClick={() => setShoweyenew(!showeyenew)}>
                                {showeyenew ?
                                  <img src={require('../../assets/images/eye.svg').default} className='img-fluid eye-show' alt="eye" />
                                  :
                                  <img src={require('../../assets/images/eye-slash.svg').default} className='img-fluid eye-hide' alt="eye-slash" />
                                }
                              </button>
                            </div>
                            {errors.newpassword && errors.newpassword.type === 'required' && <span className="errors">Please Enter New Password</span>}
                            {errors.newpassword && errors.newpassword.type === 'pattern' && <span className="errors">Password Must Contain Uppercase, Special Characters, Numbers</span>}
                          </div>
                          <div className="col-lg-6 mb-3">
                            <label className="form-label" htmlFor="confirmNewPassword">Confirm Your Password<span className="mandatory">*</span></label>
                            <div className="eyeIcon">
                              <input
                                type={showeyecon ? 'text' : 'password'}
                                id='cpassword'
                                className="form-control user"
                                onKeyDown={(event) => AvoidSpace(event)}
                                placeholder="Confirm Password"
                                {...register('cpassword', { required: true, validate: (value) => value === getValues("newpassword") })}
                              />
                              <button className="btn" type="button" onClick={() => setShoweyecon(!showeyecon)}>
                                {showeyecon ?
                                  <img src={require('../../assets/images/eye.svg').default} className='img-fluid eye-show' alt="eye" />
                                  :
                                  <img src={require('../../assets/images/eye-slash.svg').default} className='img-fluid eye-hide' alt="eye-slash" />
                                }
                              </button>
                            </div>
                            {errors.cpassword && errors.cpassword.type === 'required' && <span className='errors'>Please Enter the Confirm Password</span>}
                            {errors.cpassword && errors.cpassword.type === 'validate' && <span className='errors'>Passwords Must be Same</span>}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 my-3">
                            <button className="btn orangeBtn" disabled={button}> {button ? <ClipLoader color={'#ffffff'} size={25} /> : <span>Submit</span>}</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );

}

export default Security;