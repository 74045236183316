import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/scss/style-g.scss';
import '../src/scss/style-inner.scss';
import "../src/cmsscss/cms.scss"
import "../src/homescss/style.scss"

import Home from '../src/views/HomeNew/Home';
import Wallet from '../src/views/Wallet/Wallet';
import Deposit from '../src/views/Deposit/Deposit';
import Withdraw from '../src/views/Withdraw/Withdraw';
import Myprofile from '../src/views/Myprofile/Myprofile';
import Security from '../src/views/Security/Security';
import Login from '../src/views/Login/Login';
import Register from '../src/views/Register/Register';
import Forgot from '../src/views/Forgot/Forgot';
import Otp from '../src/views/OTP/OTP';
import VerifyToken from '../src/views/VerifyToken/VerifyToken';
import Pagenotfound from '../src/views/Pagenotfound/Pagenotfound'
import Underconstruction from '../src/views/Underconstruction/Underconstruction';
import Resetpassword from './views/Resetpassword/resetpassword'
import Tfa from './views/TFA/OTP'
import BuyTokenCen from './views/BuytokenCen/Buytoken'
import CenHistory from './views/CenHistory/History'
import IpBlock from './views/IpBlock/ipBlock'
import { CONST } from './config';
import { Helmet } from "react-helmet";
import Kyc from "../src/views/KYC/KYC";
import Helper from './common/Helper';
import { useCustomizeContext } from './common/Context';
import AboutUs from './views/AboutUs/AboutUs';
import Privacy from './views/Privacy/Privacy';
import Terms from './views/Terms/Terms';
import { useIdleTimer } from 'react-idle-timer';
import { io } from 'socket.io-client';
import { SocketContext } from './socket';
import PropTypes from 'prop-types';

const App = (props) => {
  const [isLogin, setIsLogin] = useState(localStorage.getItem('loginId'))
  const [isCurrency, setIsCurrency] = useState("")
  const [profilePic, setProfilePic] = useState()

  const {
    siteInformation: {
      setTwitter,
      setCopyrightstext,
      setFacebook,
      setFooterContent,
      setDiscord,
      setWhitePaperLink,
      setLinkedin,
      setInstagram,
      setTelegram
    },
    userInfo: {
      userAuthToken,
      setUserAuthToken
    }
  } = useCustomizeContext();

  useEffect(() => {
    let authToken = localStorage.getItem('loginId');
    if (authToken) {
      setUserAuthToken(authToken);
    }
  }, [userAuthToken]);

  useEffect(() => {
    fetchData()
    if (localStorage.getItem('loginId')) {
      setIsLogin(localStorage.getItem('loginId'))
    }
  }, [])

  const url = CONST?.BACKEND_URL
  useEffect(() => {    // Create a new Socket.IO instance and connect to the server  
    const newSocket = io(url);
    newSocket.on("check-activity", (message) => {
      if (message) {
        if (+localStorage.getItem("login_no") === +message?.login_no) {
          newSocket.emit('user-status', message);
        }
      }
    });
    return () => {
      newSocket.disconnect();
    };
  }, []);

  const socket = useContext(SocketContext);

  const logout = async () => {
    return new Promise((resolve) => {
      const loginId = localStorage.getItem('loginId');
      socket.emit("logout", loginId);

      socket.on("logout_get", (get_param) => {
        if (get_param === loginId) {
          localStorage.clear();
          sessionStorage.clear();
          setUserAuthToken("");
          resolve(); // Now this is defined within the promise
        }
      });
    });
  };

  const onIdle = async () => {
    if (userAuthToken) {
      await logout();
      return <Navigate to="/login" />;
    }
  }

  const onAction = () => {
    reset(); // Reset the idle timer on user action
  };

  const { reset } = useIdleTimer({
    timeout: 10 * 60 * 1000, // 10 minutes
    onIdle,
    onAction,
  });

  let location = window.location.pathname

  useEffect(() => {
    const tabKey = 'verifyTabOpen';
    const alertShownKey = 'alertShown';
    const isHomeRoute = location == '/home';
    const isVerifyRoute = location.startsWith('/verifyuser');
    const isResetPassword = location.startsWith('/resetpassword');

    if (!(isVerifyRoute || isHomeRoute || isResetPassword)) {
      // Check if the tab is already open
      const isTabOpen = localStorage.getItem(tabKey);

      if (isTabOpen) {
        // Check if the alert has already been shown
        if (!sessionStorage.getItem(alertShownKey)) {
          alert('Another tab is already open for verification!');
          sessionStorage.setItem(alertShownKey, 'true'); // Set flag to prevent alert again
          window.location = "https://google.com";
        }
      } else {
        // Set the key to indicate that this tab is open
        localStorage.setItem(tabKey, 'true');

        // Cleanup function to remove the key on tab close
        const handleBeforeUnload = () => {
          localStorage.removeItem(tabKey);
          sessionStorage.removeItem(alertShownKey); // Clear alert flag on unload
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
          handleBeforeUnload();
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }
    }
  }, [location]);

  const [maintain, setMaintain] = useState('')
  const [maintainone, setMaintainone] = useState('')

  const fetchData = async (values) => {
     let getdata = await Helper.PostData(`${CONST?.BACKEND_URL}/Yq7ss7Xwd8Jc/xh7lBgripKGhlAaFotjw`);
  

    if (getdata?.status === true) {
      setMaintain(getdata?.data?.sitefavicon)
      setMaintainone(getdata?.data?.maintance_detail);
      setWhitePaperLink(getdata?.data?.whitePaper);
      setTwitter(getdata?.data?.twitter);
      setFacebook(getdata?.data?.facebook);
      setFooterContent(getdata?.data?.footercontent);
      setDiscord(getdata?.data?.discord);
      setInstagram(getdata?.data?.instagram);
      setLinkedin(getdata?.data?.linkedin);
      setTelegram(getdata?.data?.telegram)
      setCopyrightstext(getdata?.data?.copyright);
      document.getElementById('faviconImg').setAttribute('href', getdata?.data?.sitefavicon);
    }
    
  }

  useEffect(() => {
    const todosString = localStorage.getItem("loginId");
    if (todosString) {
      localStorage.setItem("isActive", true);
    }
    function storageEventHandler(event) {
      if (event.key === 'loginId') {
        setUserAuthToken(event.newValue);
      }
    }
    window.addEventListener("storage", storageEventHandler);
    return () => {
      window.removeEventListener("storage", storageEventHandler);
    };
  }, []);

  // Helper function for route rendering
  const renderElement = (element, path) => {
    if (path === '/buytoken') {
      // Allow access to BuyTokenCen without login
      return element;
    }
    if (isLogin && (maintainone === 'active' || maintainone === '')) {
      return element;
    } else if (maintainone === "active") {
      return <Navigate to="/underconstruction" />;
    } else {
      return <Navigate to='/login' />;
    }
  };

  // Function to determine the element to render for the /history route
  const renderHistoryElement = () => {
    if (isLogin) {
      return <CenHistory />;
    }
    if (maintainone === "undermaintanence") {
      return <Navigate to="/underconstruction" />;
    }
    return <Navigate to='/login' />;
  };

  return (
    <React.Fragment>
      <Helmet>
        <link rel="icon" href={maintain} />
      </Helmet>
      <Router>
        <Routes history={props.history}>
          <Route path='/' element={maintainone === "undermaintanence" ? <Navigate to="/underconstruction" /> : <Home />} />
          <Route path='/home' element={maintainone === "undermaintanence" ? <Navigate to="/underconstruction" /> : <Home />} />
          <Route path='/wallet' element={renderElement(<Wallet isCurrency={isCurrency} setIsCurrency={setIsCurrency} />)} />
          <Route path='/deposit' element={renderElement(<Deposit isCurrency={isCurrency} setIsCurrency={setIsCurrency} />)} />
          <Route path='/kyc' element={renderElement(<Kyc />)} />
          <Route path='/withdraw' element={renderElement(<Withdraw isCurrency={isCurrency} setIsCurrency={setIsCurrency} />)} />
          <Route path='/myprofile' element={renderElement(<Myprofile profilePic={profilePic} setProfilePic={setProfilePic} />)} />
          <Route path='/security' element={renderElement(<Security />)} />
          <Route path='/login' element={maintainone === "undermaintanence" ? <Navigate to="/underconstruction" /> : <Login setIsLogin={setIsLogin} />} />
          <Route path='/register' element={maintainone === "undermaintanence" ? <Navigate to="/underconstruction" /> : <Register />} />
          <Route path='/forgot' element={maintainone === "undermaintanence" ? <Navigate to="/underconstruction" /> : <Forgot />} />
          <Route path='/tfa' element={maintainone === "undermaintanence" ? <Navigate to="/underconstruction" /> : <Tfa setIsLogin={setIsLogin} />} />
          <Route path='/otp' element={maintainone === "undermaintanence" ? <Navigate to="/underconstruction" /> : <Otp setIsLogin={setIsLogin} />} />
          <Route path='/verifyuser' element={<VerifyToken />} />
          <Route path='*' element={<Pagenotfound />} />
          <Route path='/resetpassword' element={<Resetpassword />} />
          <Route path='/underconstruction' element={maintainone === "undermaintanence" ? <Underconstruction /> : <Navigate to="/" />} />
          <Route path='/buytoken' element={renderElement(<BuyTokenCen isLogin={isLogin} />, '/buytoken')} />
          <Route path='/aboutus' element={<AboutUs />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/history' element={renderHistoryElement()} />
          <Route path='/ipblock' element={<IpBlock />} />
        </Routes>
      </Router>
    </React.Fragment>
  );
}
App.propTypes = {
  history: PropTypes.any,
};

export default App;
